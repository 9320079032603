<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-domain', params: { accountId: this.$route.params.accountId } }">Domains</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <h1 class="text-h6 font-weight-light text-center">Add a domain</h1>
                <!-- <h1 class="text-h6 font-weight-light text-center">{{ accountName }}</h1> -->
                <!-- <p class="text-caption text-center">Add a domain</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <!-- Register new domain -->
                    <v-card tile elevation="4" class="pa-0">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">Register a new domain</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <p>Use a domain registrar to register a new domain, then return to this page to add an existing domain.</p>
                        </v-card-text>
                        <v-expansion-panels>
                            <v-expansion-panel>
                                <v-expansion-panel-header>Where can I register a new domain?</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <p class="text-caption">
                                        <font-awesome-icon :icon="['fas', 'info-circle']" class="blue--text"/>
                                        This information is provided for your convenience only. We do <em>NOT</em> make any endorsement about the reliability or customer experience for any of these registrars. Check ratings and reviews before selecting a registrar.</p>
                                    <!-- Here we should list the registrars for which we have some integration, for example direct links to their dns editing pages, etc. -->
                                    <p><TextLink href="https://godaddy.com" target="_blank">GoDaddy</TextLink></p>
                                    <!-- <p><TextLink href="https://www.namecheap.com" target="_blank">Namecheap</TextLink></p> -->
                                    <p><TextLink href="https://duckduckgo.com/?q=best+domain+registrars" target="_blank">Find more...</TextLink></p>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-card>
                    <!-- Add an existing domain (requires verification) -->
                    <v-card tile elevation="4" class="pa-0 mt-8">
                        <v-toolbar short flat color="white">
                            <v-toolbar-title class="purple--text">Add an existing domain</v-toolbar-title>
                        </v-toolbar>
                        <v-card-text>
                            <p>You will need to verify ownership of the domain before it is added to your account.</p>
                            <p>To get started, enter the domain name below.</p>
                        </v-card-text>
                        <v-card-text class="px-5 pt-0">
                        <v-form @submit="create" onSubmit="return false;" @keyup.enter.native="create">
                            <v-text-field
                                ref="domainNameInput"
                                v-model=newDomainName
                                label="Domain Name"
                                :rules="newDomainNameRules"
                                validate-on-blur
                                color="purple"
                                required
                                hint="The name for the domain"
                                type="text"
                                outlined
                            >
                            </v-text-field>
                        </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn elevation="4" class="purple white--text" @click="create" :disabled="!isAddFormComplete">
                                <span>Create</span>
                            </v-btn>
                            <v-btn text color="grey" @click="cancel">
                                <span>Cancel</span>
                            </v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState } from 'vuex';
import { isValidName, compact } from '@/sdk/input';
import TextLink from '@/components/TextLink.vue';

export default {
    components: {
        TextLink,
    },
    data: () => ({
        account: null,
        error: null,
        newDomainName: null,
        newDomainNameRules: [
            (v) => !!v || 'Display name is required',
            (v) => !v || isValidName(compact(v)) || 'Domain name is required',
        ],
    }),
    computed: {
        ...mapState({
            session: (state) => state.session,
        }),
        accountName() {
            return this.account?.name ?? 'Unknown';
        },
        isAddFormComplete() {
            // TODO: check that domain name is not too similar to existing domain name
            return typeof this.newDomainName === 'string' && this.newDomainName.trim().length > 0;
        },
    },
    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`account/dashboard.vue: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async create() {
            try {
                this.error = false;
                this.$store.commit('loading', { createDomain: true });
                console.log('createDomain');
                const request = {
                    // name: this.name,
                    name: this.newDomainName,
                    // agreeToTerms: this.isAgreeToTermsChecked,
                    // interactionId: this.interactionId, // will be present if domain arrives from an existing interaction, such as clicking on a specific link to get started and we can use this to redirect the domain to an appropriate location after domain is created
                };
                const response = await this.$client.account(this.$route.params.accountId).domain.create(request);
                console.log('createDomain response: %o', response);
                const { isCreated, error } = response;
                if (!isCreated && error) {
                    switch (error) {
                    case 'DOMAIN_VERIFICATION_REQUIRED':
                        this.$router.push({ name: 'account-verify-domain', params: { accountId: this.$route.params.accountId, domain: this.newDomainName } });
                        break;
                    case 'DOMAIN_ALREADY_CLAIMED':
                        this.$router.push({ name: 'account-dispute-domain', params: { accountId: this.$route.params.accountId, domain: this.newDomainName } });
                        break;
                    case 'UNKNOWN':
                    default:
                        this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to create domain', message: 'Unknown error' });
                        break;
                    }
                }
                if (isCreated) {
                    this.newDomainName = null;
                    this.$router.push({ name: 'account-view-domain', params: { accountId: this.$route.params.accountId, domain: this.newDomainName } });
                }
            } catch (err) {
                console.error('failed to create domain', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { createDomain: false });
            }
        },
        cancel() {
            this.$router.go(-1);
        },
    },
    mounted() {
        this.loadAccount();
    },
};
</script>
